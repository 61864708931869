
import React from "react"
import { Elements } from 'react-stripe-elements'
import Layout from "../components/layout"
import Pricing from "../components/pricing/Pricing"
import Author from "../components/author/Author"
import Faq from "../components/faq/Faq"
import Footer from '../components/footer/Footer'
import PurchaseModal from "../components/purchase/components/PurchaseModal"

const TeamPurchasePage = ({ location }) => {
  return (
    <Layout location={location}>
      <Elements>
        <PurchaseModal/>
      </Elements>
      <Pricing/>
      <Author/>
      <Faq/>
      <Footer/>
    </Layout>
  )
}

export default TeamPurchasePage
